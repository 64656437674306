<template>
    <Introduction :url="'/brochure/cy'" :class="'introduction-cy'">  
        <template #title>
            Cy Tech
        </template>
        <template #speciality>
            наука и технологии
        </template>
        <template #min-duration>
            от 1 года
        </template>
        <template #cost>
            От 6000 €/год
        </template>
        <template #campus>
            Париж, Pau
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>