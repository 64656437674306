<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description>  
            CY Tech, ранее называвшаяся EISTI, - это крупная французская школа науки и технологий, экономики, менеджмента, гуманитарных наук и дизайна, в основном готовящая инженеров в области информатики, прикладной математики, биотехнологии, химии и гражданского строительства. Она была создана 1 января 2020 года и расположена в Cergy (пригород Парижа) и Pau. Под управлением Парижского университета CY Cergy, это одна из 205 французских инженерных школ, аккредитованных 25 февраля 2021 года для получения диплома инженера.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Science & It
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="ing">Инженер (Grande Ecole)</option>
                                <option value="ibdsd">International Bachelor Data Science by Design</option>
                                <option value="bd">Master’s Program in Big Data</option>
                                <option value="erp">MBA in ERP Management</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/cytech/CY_TECH_Ingenieur.pdf'" v-if="this.program === 'ing'" id="ing">
                        <template #title>
                            Инженер (Grande Ecole)
                        </template>
                        <template #description>
                            <p>
                                Программа CY Tech Grande Ecole подразделяется на курс пре-подготовки и основной инженерный. Она предлагает вам возможность построить профессиональную карьеру в соответствии с вашим профилем и вашими пожеланиями в четырех основных областях:
                            </p>
                            <p>
                                ПРИКЛАДНАЯ МАТЕМАТИКА
                            </p>
                            <p>
                                ИНФОРМАТИКА
                            </p>
                            <p>
                                ГРАЖДАНСКОЕ СТРОИТЕЛЬСТВО
                            </p>
                            <p>
                                БИОТЕХНОЛОГИЯ И ХИМИЯ
                            </p>
                        </template>
                        <template #duree>
                            2 года - 5 лет
                        </template>
                        <template #language>
                            французский 
                        </template>
                        <template #cost>
                             6.000 евро/год
                        </template>
                        <template #requirements>
                            французский язык уровня B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                    <Program :url="'/cytech/CY_TECH_Bachelor_Data_Science_By_Design.pdf'" v-if="this.program === 'ibdsd'" id="ibdsd">
                        <template #title>
                            International Bachelor Data Science by Design
                        </template>
                        <template #description>
                            ​​Четырехлетняя программа бакалавриата предлагает обучение, специализирующееся на науке о данных, включая проекты и стажировки.
                            По окончании степени бакалавра, при желании, Вы сможете продолжить пятый курс и получить степень инженера Grande Ecole в следующих областях: облачные вычисления, искусственный интеллект, кибербезопасность, бизнес-интеллект.
                        </template>
                        <template #duree>
                            4-5 лет
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            10.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                    </Program>

                    <Program :url="'/cytech/CY_TECH_MASTER_BIG_DATA.pdf'" v-if="this.program === 'bd'" id="bd">
                        <template #title>
                            Master’s Program in Big Data
                        </template>
                        <template #description>
                            Целью этой магистерской программы является подготовка будущих специалистов в области информационных систем. Программа включает солидный материал по математике и использует широкий спектр компьютерных инструментов, что позволяет студентам решать реальные проблемы и предлагать эффективные алгоритмические и архитектурные решения.
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            7.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                    </Program>

                    <Program :url="'/cytech/CY_TECH_MBA_ERP.pdf'" v-if="this.program === 'erp'" id="erp">
                        <template #title>
                            MBA in ERP Management
                        </template>
                        <template #description>
                        Программа предназначена для обучения и подготовки руководителей высшего уровня к использованию решений ERP. CY Tech - единственное высшее учебное заведение во Франции, которое предлагает своим студентам подготовиться к профессиональной сертификации SAP.
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            11.500 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь, январь
                        </template>
                    </Program>
                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>